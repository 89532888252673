<template>
  <div class="text-gray-600 flex items-center justify-between">
    <div class="flex items-center gap-5">
      <JUCIcon class="h-16 w-16" />
      <div class="text-center">
        <fw-label>{{ $t('edition') }}</fw-label>
        <div class="text-lg font-bold text-left">
          <span class="juc-text-color">JUC&nbsp;</span>
          <span>{{ edition?.name }}</span>
        </div>
      </div>
    </div>

    <div class="flex gap-8 justify-center text-center">
      <div>
        <fw-label>{{ $t('league') }}</fw-label>
        <div class="font-bold">{{ league?.title?.[language] }}</div>
      </div>
      <div>
        <fw-label>{{ $t('tournament') }}</fw-label>
        <div class="font-bold">{{ tournament?.title?.[language] }}</div>
      </div>
      <div>
        <fw-label>{{ $t('modality') }}</fw-label>
        <div class="font-bold">
          {{ `${sport?.modality?.title?.[language]} ${sport?.category?.[language] ?? ''}` }}
        </div>
      </div>
      <div>
        <fw-label>{{ $t('gender') }}</fw-label>
        <div class="font-bold">
          {{ genders[tournament?.gender ?? 'U'][language] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JUCIcon from '@/components/icons/JUCIcon.vue'
import { GENDERS } from '@/utils/index.js'
export default {
  name: 'PanelSignupTournament',
  components: {
    JUCIcon,
  },
  props: {
    tournament: {
      type: Object,
      default: () => {},
    },
    league: {
      type: Object,
      default: () => {},
    },
    sport: {
      type: Object,
      default: () => {},
    },
    edition: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      genders: GENDERS,
    }
  },

  computed: {
    language() {
      return this.$store.state.language || 'pt'
    },
  },
}
</script>

<i18n>
  {
    "en": {
      "gender": "Gender",
      "modality": "Modality",
      "tournament": "Tournament",
      "league": "League",
      "edition": "Edition"
    },
    "pt": {
      "gender": "Género",
      "modality": "Modalidade",
      "tournament": "Torneio",
      "league": "Liga",
      "edition": "Edição"
    }
  }
</i18n>
